export  const NavLinks = [
{
    name: "Ventas",
    path: "/ventas"
},
// {
//     name: "Productos",
//     path: "/productos"
// },
{
    name:"Gastos",
    path: "/gastos"
}]


// export const url= "https://minimarket-virgen-lourdes-backend.onrender.com/"

 //export const url= "http://vps-4404045-x.dattaweb.com:8081/" 

 export const url="https://backend.minimercado.site/" 